"use client";

import { useEffect, useState } from 'react';
import SanityImage from '@sanity-image';
import styles from "./building-card.module.scss";
import MarkdownParser from "components/rich-text/markdown-parser";
import ClockSVG from "assets/icons/clock.svg";
import { getClosingTimesById } from "lib/buildings";
import RedundantLink from "components/accesibility/redundant-link";
import GTMLink from "components/controls/links/GTMLink";
import Badge from 'components/badge';
import { Image } from '@sanity/types';
const BuildingCard = ({
  building,
  analytics
}: Props) => {
  const {
    _id,
    title,
    link,
    image,
    description,
    hoursCard
  } = building;
  const [currentHours, setCurrentHours] = useState({
    open: null,
    message: null
  });
  useEffect(() => {
    getClosingTimesById(_id).then(data => {
      setCurrentHours(data);
    });
  }, [_id]);
  const badgeColor = currentHours?.open ? `var(--color-green-good-news-400)` : `var(--color-met-red-dark)`;
  return <div className={styles.buildingCard} data-sentry-component="BuildingCard" data-sentry-source-file="building-card.tsx">
			<div className={styles.imageWrapper}>
				<RedundantLink href={link} title={`${title} Landing Page`} Content_Type={building?._type} Content_Name={title} Content_Block={analytics?.content_block} data-sentry-element="RedundantLink" data-sentry-source-file="building-card.tsx" />
				{image && <SanityImage fill={true} image={image} sizes={"(max-width: 700px) 85vw, 50vw"} />}
				{currentHours?.message && <div className={styles.badgeWrapper}>
						<Badge icon={<ClockSVG />} color={badgeColor} text={currentHours.message} />
					</div>}
			</div>
			<div className={styles.body}>
				<GTMLink className={styles.link} href={link} Content_Type={building?._type} Content_Name={title} Content_Block={analytics?.content_block} data-sentry-element="GTMLink" data-sentry-source-file="building-card.tsx">
					<h2 className={styles.title}>
						<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="building-card.tsx">{title}</MarkdownParser>
					</h2>
				</GTMLink>
				<div className={styles.description}>{description}</div>

				<div className={styles.info}>
					{hoursCard?.hoursOpen && <div className={styles.time}>
							<span className={styles.label}>Hours: </span>
							{hoursCard?.hoursOpen}
						</div>}

					{hoursCard?.hoursExtended && <div className={styles.time}>
							<span className={styles.label}>Extended Hours: </span>
							{hoursCard?.hoursExtended}
						</div>}

					{hoursCard?.daysClosed && <div className={styles.time}>
							<span className={styles.label}>Closed: </span>
							{hoursCard?.daysClosed}
						</div>}
				</div>
				<div className={styles.daysClosed}>
					{hoursCard?.daysOfTheYearClosed}
				</div>
			</div>
		</div>;
};
interface Props {
  building: {
    _id: any;
    _type: any;
    description: string;
    hoursCard: {
      daysClosed: string;
      daysOfTheYearClosed: string;
      hoursExtended: string;
      hoursOpen: string;
    };
    image: Image;
    link: string;
    title: string;
  };
  analytics: {
    shouldTrackClicks: boolean;
    content_block: string;
  };
}
export default BuildingCard;